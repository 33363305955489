import { getScaledValue } from './common';

import type { Parameters } from '..';

export function hardShadow(parameters: Parameters) {
  return `
    hsla(${parameters.uiHue}, 20%, 20%,
      ${getScaledValue(parameters.styleStrength, 1, 100, 0, 1).toFixed(2)})
  `;
}
export function softShadow(parameters: Parameters) {
  return `
    hsla(${parameters.bgHue}, 20%, 20%,
      ${getScaledValue(parameters.styleStrength, 1, 100, 0.05, 0.4).toFixed(2)})
  `;
}

export function badgeShadow(parameters: Parameters) {
  const shadows: any = {
    box: `2px 3px 0 ${hardShadow(parameters)}`,
  };

  return shadows[parameters.style] || 'none';
}

export function buttonShadow(parameters: Parameters) {
  const shadows: any = {
    box: `2px 3px 0 ${hardShadow(parameters)}`,
  };

  return shadows[parameters.style] || 'none';
}

export function cardShadow(parameters: Parameters) {
  const shadows: any = {
    leap: '1px 1px 0px hsla(0,0%,0%,0.1)',
    box: `5px 5px 0 ${hardShadow(parameters)}`,
    raised: `20px 20px 40px ${softShadow(parameters)}`,
  };

  return shadows[parameters.style] || 'none';
}

export function iconShadow(parameters: Parameters) {
  const shadows: any = {
    leap: '1px 1px 0px hsla(0,0%,0%,0.1)',
    box: `2px 2px 0 ${hardShadow(parameters)}`,
    raised: `5px 5px 20px ${softShadow(parameters)}`,
  };

  return shadows[parameters.style] || 'none';
}

export function imageShadow(parameters: Parameters) {
  const colors = {
    leap: '5px 5px 10px hsla(0,0%,0%,0.1)',
    flat: 'none',
    book: 'none',
    lined: 'none',
    box: `5px 5px 0 ${hardShadow(parameters)}`,
    raised: `15px 15px 40px ${softShadow(parameters)}`,
  };
  return colors[parameters.style];
}
