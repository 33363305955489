import type { Parameters } from '..';

export function clamp(val: number, min: number, max: number) {
  return val > max ? max : val < min ? min : val;
}

export function getScaledValue(
  value: number,
  sourceRangeMin: number,
  sourceRangeMax: number,
  targetRangeMin: number,
  targetRangeMax: number
) {
  var targetRange = targetRangeMax - targetRangeMin;
  var sourceRange = sourceRangeMax - sourceRangeMin;
  return (
    ((value - sourceRangeMin) * targetRange) / sourceRange + targetRangeMin
  );
}

export function minMaxLum(parameters: Parameters) {
  return parameters.bgStyle === 'solid'
    ? parameters.uiLum
    : getScaledValue(parameters.uiLum, 2, 98, 15, 95).toFixed();
}
