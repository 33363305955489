import { camelToDasherized } from '-/utils';
import { PRESET_DEFAULTS } from '..';

import type { CssVariables, PresetName } from '..';

interface ThemeStylesProps {
  root?: string;
  cssVariables: CssVariables;
  coverImageUrl?: string;
  profileImageUrl?: string;
  hasSavedTheme?: boolean;
  presetName: PresetName;
}

const ThemeStyles = ({
  root = ':root',
  cssVariables: theme,
  coverImageUrl,
  profileImageUrl,
  presetName,
}: ThemeStylesProps) => {
  let variables = Object.entries(theme)
    .map(([key, value]) => {
      return `--${camelToDasherized(key)}: ${value};`;
    })
    .join(' ');

  variables += `--header: url(${
    coverImageUrl || PRESET_DEFAULTS.coverImageUrl[presetName]
  });`;

  if (profileImageUrl) {
    variables += `--avatar: url(${profileImageUrl});`;
  }

  return (
    <style
      data-testid="theme-styles"
      dangerouslySetInnerHTML={{ __html: `${root} {${variables}};` }}
    />
  );
};

export { ThemeStyles };
