import { solid } from './colors';
import { getScaledValue } from './common';

import type { Parameters } from '..';

export function profileImageSize(spacing: number) {
  return getScaledValue(spacing, 0, 100, 5, 10).toFixed(2);
}
export function iconSize(fontSize: number) {
  return getScaledValue(fontSize, 0, 100, 0.875, 1.25).toFixed(2);
}

export function imageOverlaySolid(parameters: Parameters) {
  if (hasLinearGradientOverlay(parameters)) {
    return 'inherit';
  }
  return solidOverlay(parameters);
}

export function solidOverlay(parameters: Parameters) {
  return `hsla(${parameters.bgHue}, ${parameters.saturation}%, ${
    parameters.bgLum
  }%, ${getScaledValue(parameters.blend, 1, 100, 0, 0.3).toFixed(2)})`;
}

export function imageOverlayGradient(parameters: Parameters) {
  if (hasLinearGradientOverlay(parameters)) {
    return `linear-gradient(0deg, ${solid(parameters)} 0%, ${solidOverlay(
      parameters
    )} 50%)`;
  } else {
    return 'none';
  }
}

const hasLinearGradientOverlay = (parameters: Parameters) =>
  parameters.bgStyle === 'gradient' &&
  (parameters.layout === 'portrait' || parameters.layout === 'banner');
