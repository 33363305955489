import { getScaledValue } from './common';

import type { Parameters } from '..';

export function gapBanner(parameters: Parameters) {
  return getScaledValue(parameters.spacing, 0, 100, 8, 30).toFixed(2);
}
export function gapSpacer(parameters: Parameters) {
  return getScaledValue(parameters.spacing, 0, 100, 0.5, 1).toFixed(2);
}
export function dividerWidth(parameters: Parameters) {
  return getScaledValue(parameters.styleStrength, 0, 100, 10, 90).toFixed();
}

export function badgePadding(parameters: Parameters) {
  const padding = {
    leap: '0.125rem 0.5rem',
    flat: '0.25rem 1rem',
    book: '0.25rem 0',
    lined: '0.5rem 1rem',
    box: '0.25rem 1rem',
    raised: '0.25rem 1rem',
  };
  return padding[parameters.style];
}

export function badgeRadius(parameters: Parameters) {
  if (parameters.style === 'book') return '0';
  return '1rem';
}
