import { getScaledValue } from './common';

import type { Parameters } from '..';

export function textHandleSize(parameters: Parameters) {
  return `${getScaledValue(parameters.fontSize, 0, 100, 1.25, 2).toFixed(
    2
  )}rem`;
}
export function textDisplaySize(parameters: Parameters) {
  return `${getScaledValue(parameters.fontSize, 0, 100, 1.5, 2.5).toFixed(
    2
  )}rem`;
}
export function textDisplayWeight(parameters: Parameters) {
  return getScaledValue(parameters.textStrength, 0, 100, 300, 700).toFixed();
}
export function textTitleSize(parameters: Parameters) {
  return `${getScaledValue(parameters.fontSize, 0, 100, 1.1, 1.4).toFixed(
    2
  )}rem`;
}
export function textTitleWeight(parameters: Parameters) {
  return getScaledValue(parameters.textStrength, 0, 100, 400, 800).toFixed();
}
export function textBodySize(parameters: Parameters) {
  return `${getScaledValue(parameters.fontSize, 0, 100, 0.9, 1.1).toFixed(
    2
  )}rem`;
}
export function textBodyWeight(parameters: Parameters) {
  return getScaledValue(parameters.textStrength, 0, 100, 350, 500).toFixed();
}
export function textButtonWeight(parameters: Parameters) {
  return getScaledValue(parameters.textStrength, 0, 100, 400, 600).toFixed();
}

export function badgeTextSize(parameters: Parameters) {
  if (parameters.style === 'leap') return '0.875rem';
  return textBodySize(parameters);
}
