import { colord, extend } from 'colord';
import a11yPlugin from 'colord/plugins/a11y';
import mixPlugin from 'colord/plugins/mix';

import { PresetNames, PresetTheLeap } from '..';
import { CUSTOM } from '../constants';
import { minMaxLum, getScaledValue, clamp } from './common';

import type { Parameters, Preset } from '..';

extend([mixPlugin]);
extend([a11yPlugin]);

const baseTextColors = (saturation: number) => {
  return [
    { name: 'gray-1', value: 'hsl(0, 5%, 10%)' },
    { name: 'gray-2', value: 'hsl(0, 5%, 25%)' },
    { name: 'gray-3', value: 'hsl(0, 5%, 40%)' },
    { name: 'gray-4', value: 'hsl(0, 5%, 50%)' },
    { name: 'gray-5', value: 'hsl(0, 5%, 75%)' },
    { name: 'gray-6', value: 'hsl(0, 5%, 85%)' },
    { name: 'gray-7', value: 'hsl(0, 5%, 93%)' },
    { name: 'gray-8', value: 'hsl(0, 5%, 98%)' },

    { name: 'red-1', value: `hsl(0, ${saturation}%, 90%)` },
    { name: 'red-2', value: `hsl(0, ${saturation}%, 75%)` },
    { name: 'red-3', value: `hsl(0, ${saturation}%, 20%)` },
    { name: 'red-4', value: `hsl(0, ${saturation}%, 50%)` },
    { name: 'red-5', value: `hsl(0, ${saturation}%, 35%)` },

    { name: 'yellow-1', value: `hsl(40, ${saturation}%, 25%)` },
    { name: 'yellow-2', value: `hsl(40, ${saturation}%, 50%)` },
    { name: 'yellow-3', value: `hsl(35, ${saturation}%, 75%)` },
    { name: 'yellow-4', value: `hsl(40, ${saturation}%, 90%)` },

    { name: 'brown', value: 'hsl(12, 55%, 19%)' },

    { name: 'lime-1', value: `hsl(80, ${saturation}%, 90%)` },
    { name: 'lime-2', value: `hsl(80, ${saturation}%, 75%)` },
    { name: 'lime-3', value: `hsl(80, ${saturation}%, 50%)` },
    { name: 'lime-4', value: `hsl(80, ${saturation}%, 30%)` },

    { name: 'green-1', value: `hsl(120, ${saturation}%, 25%)` },
    { name: 'green-2', value: `hsl(120, ${saturation}%, 40%)` },
    { name: 'green-3', value: `hsl(120, ${saturation}%, 75%)` },
    { name: 'green-4', value: `hsl(120, ${saturation}%, 90%)` },

    { name: 'teal-1', value: `hsl(160, ${saturation}%, 90%)` },
    { name: 'teal-2', value: `hsl(160, ${saturation}%, 75%)` },
    { name: 'teal-3', value: `hsl(160, ${saturation}%, 40%)` },
    { name: 'teal-4', value: `hsl(160, ${saturation}%, 25%)` },

    { name: 'sky-1', value: `hsl(200, ${saturation}%, 25%)` },
    { name: 'sky-2', value: `hsl(200, ${saturation}%, 40%)` },
    { name: 'sky-3', value: `hsl(200, ${saturation}%, 75%)` },
    { name: 'sky-4', value: `hsl(200, ${saturation}%, 90%)` },

    { name: 'blue-1', value: `hsl(240, ${saturation}%, 90%)` },
    { name: 'blue-2', value: `hsl(240, ${saturation}%, 75%)` },
    { name: 'blue-3', value: `hsl(240, ${saturation}%, 40%)` },
    { name: 'blue-4', value: `hsl(240, ${saturation}%, 25%)` },

    { name: 'purple-1', value: `hsl(280, ${saturation}%, 30%)` },
    { name: 'purple-2', value: `hsl(280, ${saturation}%, 50%)` },
    { name: 'purple-3', value: `hsl(280, ${saturation}%, 75%)` },
    { name: 'purple-4', value: `hsl(280, ${saturation}%, 90%)` },

    { name: 'pink-1', value: `hsl(320, ${saturation}%, 90%)` },
    { name: 'pink-2', value: `hsl(320, ${saturation}%, 75%)` },
    { name: 'pink-3', value: `hsl(320, ${saturation}%, 50%)` },
    { name: 'pink-4', value: `hsl(320, ${saturation}%, 30%)` },
  ];
};

function getAccessibleColor(
  foreground: string,
  background: string,
  darken: number,
  lighten: number,
  desaturate: number
) {
  if (isAccessible(foreground, background)) return foreground;

  return colord(background).isLight()
    ? colord(background).darken(darken).desaturate(desaturate).toHslString()
    : colord(background).lighten(lighten).desaturate(desaturate).toHslString();
}

function isAccessible(foreground: string, background: string) {
  if (colord(foreground).contrast(background) < 4) {
    return;
  }

  return true;
}

export function hexToHsl(hex: string) {
  return colord(hex).toHsl();
}

export function bgColorUi(parameters: Parameters) {
  if (parameters.style === 'leap') return 'hsl(65, 93%, 65%)';
  return `hsl(${parameters.uiHue}, ${parameters.saturation}%, ${minMaxLum(
    parameters
  )}%)`;
}

export function bgColorUiDesaturated(parameters: Parameters) {
  return `hsl(${parameters.uiHue}, 5%, ${minMaxLum(parameters)}%)`;
}

export function bgColorUiShade(parameters: Parameters) {
  return parameters.styleStrength < 50
    ? colord(bgColorUi(parameters))
        .darken(getScaledValue(parameters.styleStrength, 0, 49, 0.08, 0))
        .toHslString()
    : colord(bgColorUi(parameters))
        .lighten(getScaledValue(parameters.styleStrength, 50, 100, 0, 0.08))
        .toHslString();
}

export function bgColorUiTransparent(parameters: Parameters) {
  return `hsla(${parameters.uiHue}, ${parameters.saturation}%, ${
    parameters.uiLum
  }%, ${getScaledValue(parameters.styleStrength, 1, 100, 0, 0.5).toFixed(2)})`;
}

export function solid(parameters: Parameters) {
  return `hsl(${parameters.bgHue}, ${parameters.saturation}%, ${parameters.bgLum}%)`;
}

export function gradient(parameters: Parameters, colorStop = '20rem') {
  return `linear-gradient(180deg, hsl(${parameters.uiHue}, ${
    parameters.saturation
  }%, ${clamp(parameters.bgLum, 15, 95)}%) 0%, hsl(${parameters.bgHue}, ${
    parameters.saturation
  }%, ${parameters.bgLum}%) ${colorStop})`;
}

export function bgColorSolid(parameters: Parameters) {
  if (parameters.bgStyle === 'gradient') return 'inherit';
  return solid(parameters);
}

export function bgColorGradient(parameters: Parameters) {
  if (parameters.bgStyle === 'solid') return 'none';
  return gradient(parameters);
}

export function bgColor(parameters: Parameters, colorStop = '20rem') {
  if (parameters.bgStyle === 'gradient') {
    return gradient(parameters, colorStop);
  }
  return solid(parameters);
}

export function bgColorPageShade(parameters: Parameters) {
  return parameters.styleStrength < 50
    ? colord(solid(parameters))
        .darken(getScaledValue(parameters.styleStrength, 0, 49, 0.08, 0))
        .toHslString()
    : colord(solid(parameters))
        .lighten(getScaledValue(parameters.styleStrength, 50, 100, 0, 0.08))
        .toHslString();
}
export function bgColorPageLight(parameters: Parameters) {
  return colord(solid(parameters)).lighten(0.07).toHslString();
}
export function textColor(parameters: Parameters) {
  if (parameters.preset === PresetNames.THE_LEAP)
    return accessibleTextOnPage(parameters);
  return baseTextColor(parameters);
}

export function textColorSecondary(parameters: Parameters) {
  if (parameters.preset === PresetNames.THE_LEAP) return '';
  return baseTextColor(parameters);
}

export function baseTextColor(parameters: Parameters) {
  return (
    baseTextColors(parameters.saturation).find(
      (o) => o.name === parameters.fontColor
    )?.value || 'hsl(0, 5%, 98%)'
  );
}
export function bgColorBody(parameters: Parameters) {
  return colord(solid(parameters)).desaturate(0.12).darken(0.06).toHslString();
}
export function accessibleTextOnUi(parameters: Parameters) {
  if (parameters.style === 'leap') return 'hsl(12, 55%, 19%)';
  return getAccessibleColor(
    baseTextColor(parameters),
    bgColorUi(parameters),
    0.85,
    0.85,
    0.4
  );
}
export function accessibleTextOnPage(parameters: Parameters) {
  return getAccessibleColor(
    baseTextColor(parameters),
    bgColorSolid(parameters),
    0.5,
    0.5,
    0.4
  );
}
export function accessibleTextOnUiShade(parameters: Parameters) {
  return getAccessibleColor(
    baseTextColor(parameters),
    bgColorUiShade(parameters),
    0.5,
    0.6,
    0.4
  );
}
export function accessibleTextOnPageShade(parameters: Parameters) {
  return getAccessibleColor(
    baseTextColor(parameters),
    bgColorPageShade(parameters),
    0.5,
    0.5,
    0.4
  );
}
export function mixedColors(parameters: Parameters) {
  return colord(bgColorUiTransparent(parameters))
    .mix(solid(parameters))
    .toHslString();
}
export function accessibleTextOnTransparent(parameters: Parameters) {
  return getAccessibleColor(
    baseTextColor(parameters),
    mixedColors(parameters),
    0.6,
    0.6,
    0.4
  );
}

export function outlineColor(parameters: Parameters) {
  if (parameters.style === 'leap') return 'hsl(0, 0%, 63%)';
  if (parameters.style !== 'lined') return 'transparent';

  return colord(baseTextColor(parameters))
    .alpha(getScaledValue(parameters.styleStrength, 0, 100, 0.1, 1))
    .toHslString();
}
export function accessibleFontColors(parameters: Parameters) {
  return baseTextColors(parameters.saturation).filter((color) =>
    isAccessible(color.value, solid(parameters))
  );
}

// Badges
export function badgeTextColor(parameters: Parameters) {
  const colors = {
    leap: 'hsl(12, 55%, 19%)',
    flat: accessibleTextOnUi(parameters),
    book: baseTextColor(parameters),
    lined: accessibleTextOnTransparent(parameters),
    box: accessibleTextOnUi(parameters),
    raised: accessibleTextOnUi(parameters),
  };
  return colors[parameters.style];
}

export function badgeBgColor(parameters: Parameters) {
  const colors = {
    leap: 'hsl(340, 9%, 94%)',
    flat: bgColorUi(parameters),
    book: 'transparent',
    lined: bgColorUiTransparent(parameters),
    box: 'transparent',
    raised: bgColorUi(parameters),
  };
  return colors[parameters.style];
}

export function badgeOutlineColor(parameters: Parameters) {
  const colors = {
    leap: 'hsl(0, 0%, 63%)',
    flat: 'transparent',
    book: 'transparent',
    lined: outlineColor(parameters),
    box: accessibleTextOnUi(parameters),
    raised: 'transparent',
  };

  return colors[parameters.style];
}

export function badgeBorderColor(parameters: Parameters) {
  if (parameters.style === 'book') return bgColorUi(parameters);
  return 'transparent';
}

// Buttons
export function buttonTextColor(parameters: Parameters) {
  if (parameters.style === 'leap') return 'hsl(12, 55%, 19%)';
  return accessibleTextOnUi(parameters);
}

export function buttonBgColor(parameters: Parameters) {
  if (parameters.style === 'leap') return 'hsl(65, 93%, 65%)';
  return bgColorUi(parameters);
}

export function buttonOutlineColor(parameters: Parameters) {
  const colors = {
    leap: 'hsl(12, 55%, 19%)',
    flat: bgColorUi(parameters),
    book: 'transparent',
    lined: outlineColor(parameters),
    box: accessibleTextOnUi(parameters),
    raised: bgColorUi(parameters),
  };

  return colors[parameters.style];
}

// Icons
export function iconTextColor(parameters: Parameters) {
  const colors = {
    leap: 'hsl(12, 55%, 19%)',
    flat: accessibleTextOnUi(parameters),
    book: baseTextColor(parameters),
    lined: accessibleTextOnTransparent(parameters),
    box: accessibleTextOnUi(parameters),
    raised: baseTextColor(parameters),
  };

  return colors[parameters.style];
}

export function iconBgColor(parameters: Parameters) {
  const colors = {
    leap: 'hsl(340, 9%, 94%)',
    flat: bgColorUi(parameters),
    book: 'transparent',
    lined: bgColorUiTransparent(parameters),
    box: bgColorUi(parameters),
    raised: bgColorPageLight(parameters),
  };

  return colors[parameters.style];
}

export function iconOutlineColor(parameters: Parameters) {
  const colors = {
    leap: 'hsl(0, 0%, 63%)',
    flat: 'transparent',
    book: 'transparent',
    lined: outlineColor(parameters),
    box: accessibleTextOnUi(parameters),
    raised: 'transparent',
  };

  return colors[parameters.style];
}

// Cards
export function cardBgColor(parameters: Parameters) {
  const colors = {
    leap: 'hsl(0, 0%, 98%)',
    flat: bgColorPageShade(parameters),
    book: 'transparent',
    lined: 'transparent',
    box: bgColorUi(parameters),
    raised: bgColorPageLight(parameters),
  };
  return colors[parameters.style];
}

export function cardTextColor(parameters: Parameters) {
  const colors = {
    leap: 'hsl(12, 55%, 19%)',
    flat: accessibleTextOnPageShade(parameters),
    book: textColor(parameters),
    lined: textColor(parameters),
    box: accessibleTextOnUi(parameters),
    raised: textColor(parameters),
  };
  return colors[parameters.style];
}

export function imageOutlineColor(parameters: Parameters) {
  const colors = {
    leap: 'transparent',
    flat: 'transparent',
    book: bgColorUi(parameters),
    lined: outlineColor(parameters),
    box: 'transparent',
    raised: 'transparent',
  };
  return colors[parameters.style];
}

export const customColorVariation = (
  backgroundColor: string,
  preset: Preset = PresetTheLeap
) => {
  const { h, l, s } = hexToHsl(backgroundColor);
  return {
    ...preset.colorVariations[0],
    name: CUSTOM,
    bgHue: h,
    bgLum: l,
    saturation: s,
  };
};
